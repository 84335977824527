import yletv1Logo from './assets/yle-tv1.png'
import yletv2Logo from './assets/yle-tv2.png'
import yleteemafemLogo from './assets/yle-teemafem.png'
import tv5Logo from './assets/tv5.png'
import subLogo from './assets/sub.png'
import livLogo from './assets/liv.png'
import kutonenLogo from './assets/kutonen.png'
import jimLogo from './assets/jim.png'
import nelonenLogo from './assets/nelonen.png'
import avaLogo from './assets/ava.png'
import friiLogo from './assets/frii.png'
import mtv3Logo from './assets/mtv3.png'
import alfaLogo from './assets/alfatv.png'
import heroLogo from './assets/hero.png'
import estradiLogo from './assets/estradi.png'
import nationalgeographicLogo from './assets/natgeo.png'
import tlcLogo from './assets/tlc.png'
import starchannelLogo from './assets/starchannel.png'
import eveoLogo from './assets/eveo.png'

export const channelThemes = {
  colors: {
    yletv1hd: '#6FA0FF',
    yletv2hd: '#B134F5',
    yleteemafemhd: '#BCD61D',
    tv5: '#B5B5B5',
    mtvsubhd: '#EBD40F',
    liv: '#00E000',
    kutonen: '#87E3D8',
    jim: '#F7F263',
    nelonen: '#5570F5',
    avahd: '#853D60',
    frii: '#C2485A',
    mtv3: '#E43112',
    alfa: '#A42F12',
    hero: '#DF9387',
    estradi: '#CA005D',
    nationalgeographichd: '#fbdc3a',
    tlc: '#ed697b',
    eveohd: '#36e7df',
    starchannel: '#d6d6d6',
  },
  logos: {
    yletv1hd: yletv1Logo,
    yletv2hd: yletv2Logo,
    yleteemafemhd: yleteemafemLogo,
    tv5: tv5Logo,
    mtvsubhd: subLogo,
    liv: livLogo,
    kutonen: kutonenLogo,
    jim: jimLogo,
    nelonen: nelonenLogo,
    avahd: avaLogo,
    frii: friiLogo,
    mtv3: mtv3Logo,
    alfa: alfaLogo,
    hero: heroLogo,
    estradi: estradiLogo,
    nationalgeographichd: nationalgeographicLogo,
    tlc: tlcLogo,
    starchannel: starchannelLogo,
    eveohd: eveoLogo,
  }
};
